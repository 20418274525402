<template>
  <div class="helpComponent">
    <div class="row text-center mt-4">
      <div class="col-12">
        <h2 class="help-title"> Comment utiliser votre plateforme Accompagnement retraite ?</h2>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          1. Créer votre espace personnel
        </h3>
        <p>
          Créer directement votre compte personnel
          afin de commencer l’étude des conditions de votre départ à la retraite. <br>
          Votre identifiant peut-être votre adresse mail professionnelle ou toute autre adresse personnelle.
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          2. Renseigner vos informations et charger vos documents
        </h3>
        <p>
          Pour constituer votre <strong>bilan retraite individuel</strong>, votre conseiller retraite a besoin de
          plusieurs informations à savoir :
          <ul>
            <li>
              Votre carte d'identité
            </li>
            <li>
              Votre <strong>dossier</strong>, nombre d'enfants, service militaire, etc.
            </li>
          </ul>
        </p>

        <p>
          Une fois toutes ces informations fournies et validées, votre conseiller retraite procède à la constitution de votre
          bilan retraite individuel.
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          3. Récupérer votre bilan retraite individuel
        </h3>
        <p>
          Une fois vos informations validées, votre conseiller retraite vous informe par mail que votre
          dossier est complet et bien pris en charge.
        </p>
        <p>
          Votre <strong>bilan retraite individuel</strong> vous
          sera ensuite mis à disposition sur votre espace personnel sécurisé. Vous pouvez alors le télécharger et êtes
          invité à prendre rendez-vous pour un <strong>entretien téléphonique</strong> avec votre conseiller Audiens afin que
          vous puissiez échanger sur ce document, le faire compléter et/ou le modifier.
        </p>
        <p>
          Audiens vous propose ensuite un entretien téléphonique une fois votre bilan retraite individuel mis à
          disposition dans votre espace sécurisé.
          L’entretien se déroule principalement par téléphone. Mais, si votre situation le
          nécessite, il peut être réalisé par visio-conférence ou en rendez-vous direct.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'helpComponent',
}
</script>
